
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useFeedbackContext } from '@/context/use-feedback-context';
import { useTranslate } from '@/core/i18n';
const PageNotFound = () => {
    const { t } = useTranslate();
    const router = useRouter();
    const { addFeedback } = useFeedbackContext();
    useEffect(() => {
        addFeedback({
            title: t('notfound:feedback.title'),
            kind: 'warning',
            children: t('notfound:feedback.text'),
        });
        void router.push('/');
    }, [router.asPath, addFeedback, router, t]);
    return null;
};
export default PageNotFound;

    async function __Next_Translate__getStaticProps__195fbad5208__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195fbad5208__ as getStaticProps }
  